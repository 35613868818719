import React, {useEffect, useState} from "react";
import renderHTML from 'react-render-html';
import {withTranslation} from "react-i18next";
import {Grid, Header} from "semantic-ui-react";

import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import config from "../../config/main.config";
import sharedConfig from "../../../shared/sharedConfig";
import {Helmet} from "react-helmet";
import {getAppointment, mailtoBody} from "./util";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import moment from "moment";
import 'moment/locale/de';
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import history from "../../helper/browserHistory";

const ViewAppointment = (props) => {
    let breadCrumbData = [];
    const [appointment, setAppointment] = useState({});


    useEffect(() => {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }
        getAppointment(props.match.params.id).then(appointment_ => {
            setAppointment(appointment_);
            if (props.route && props.route.breadCrumbData) {
                breadCrumbData = [...props.route.breadCrumbData];
                if (appointment_.hasOwnProperty("name")) {
                    breadCrumbData.push({
                        title: appointment_.name,
                        url: '/termine/' + appointment.id
                    });
                }
            }
        });
    }, [])

    return (
        <div className={'grid ui'} style={{paddingTop: '300px'}}>
            <Helmet>
                <meta name="title" content={appointment.header}/>
                <meta name="description" content={appointment.description}/>

                <meta property="og:type" content="website"/>
                <meta property="og:url" content={config.URL}/>
                <meta property="og:title" content={appointment.header}/>
                <meta property="og:description" content={appointment.description}/>
                <meta property="og:image" content={config.BASE_URL_IMAGES + "all-images/" + appointment.thumbnail}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={config.URL}/>
                <meta property="twitter:title"
                      content={appointment.header}/>
                <meta property="twitter:description"
                      content={appointment.description}/>
                <meta property="twitter:image"
                      content={config.BASE_URL_IMAGES + "all-images/" + appointment.thumbnail}/>
            </Helmet>
            <Grid.Row>
                <Grid.Column style={{display: 'none'}}>
                    <BreadCrumbs breadCrumbData={breadCrumbData}/>
                </Grid.Column>
            </Grid.Row>
            <div className={'row centered'}>
                <div className={'twelve wide column'}>
                    <Button floated={"left"} onClick={_ => history.push('/de-DE/termine/')}>Zurück</Button>
                    {appointment.registerRequired === 1 ?
                        <a href={`mailto:${appointment.contact}?subject=${mailtoBody(appointment, 'subject')}&body=${mailtoBody(appointment, 'body')}`}>
                            <Button disabled={appointment.fullyBooked}
                                    floated={"right"}>{appointment.fullyBooked ? 'Keine Plätze frei' : 'Anmelden'}</Button>
                        </a> : null}
                </div>
            </div>
            <div className={'row centered'}>
                {appointment.thumbnail ? <div className={'twelve wide computer fourteen wide mobile column'}>
                    <picture className={'previewImage'}>
                        <source media={"(min-width: 1024px)"}
                                srcSet={config.BASE_URL_IMAGES + "desktop/" + appointment.thumbnail.slice(0, appointment.thumbnail.lastIndexOf('.')) + `.webp ${sharedConfig.IMAGE_SIZES.LARGE}w`
                                    + "," + config.BASE_URL_IMAGES + "desktop/" + appointment.thumbnail}/>
                        <source media={"(min-width: 321px)"}
                                srcSet={config.BASE_URL_IMAGES + "tablet/" + appointment.thumbnail.slice(0, appointment.thumbnail.lastIndexOf('.')) + `.webp ${sharedConfig.IMAGE_SIZES.MEDIUM}w`
                                    + "," + config.BASE_URL_IMAGES + "tablet/" + appointment.thumbnail}/>
                        <source media={"(max-width: 320px)"}
                                srcSet={config.BASE_URL_IMAGES + "mobile/" + appointment.thumbnail.slice(0, appointment.thumbnail.lastIndexOf('.')) + `.webp ${sharedConfig.IMAGE_SIZES.SMALL}w`
                                    + "," + config.BASE_URL_IMAGES + "mobile/" + appointment.thumbnail}/>
                        <img itemProp={'image'}
                             className={'previewImage'}
                             src={config.BASE_URL_IMAGES + 'all-images/' + appointment.thumbnail}
                             alt={appointment.thumbnail}/>
                    </picture>
                </div> : null}
                <Divider horizontal/>
                <div className={'twelve wide computer fourteen wide mobile column'}>
                    <h1>{appointment.name} </h1>
                    <Header sub>{moment(appointment.dateStart).format('dddd, DD.MM.yyyy')}</Header>
                    <p>Von {moment(appointment.dateStart).format('HH:mm')} bis {moment(appointment.dateEnd).format('HH:mm')} Uhr</p>
                    <p>{appointment.description && renderHTML(appointment.description)}</p>
                </div>
            </div>
        </div>
    )
}

export default withTranslation(['common'])(ViewAppointment)
